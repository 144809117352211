import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import { spacing24, spacing48, spacing8 } from 'src/styles/spacings'

export const Section = styled.section`
  display: flex;
  align-items: center;
  padding: ${spacing48} 0;
  font-family: 'Sora', sans-serif;
`

export const MainSectionTitle = styled.h2`
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  font-weight: 600;
  color: ${grayscale[500]};
  margin-bottom: ${spacing48};
  padding-bottom: 0;

  @media ${device.tablet} {
    font-size: 20px;
    line-height: 25px;
  }

  @media ${device.desktopLG} {
    font-size: 32px;
    line-height: 40px;
    padding-bottom: ${spacing24};
  }

  @media ${device.desktopXL} {
    font-size: 40px;
    line-height: 50px;
  }

  span {
    display: block;
    @media ${device.tablet} {
      display: inline;
    }
  }
`

export const SecondarySectionTitle = styled.h3`
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  color: ${grayscale[500]};
  margin-bottom: ${spacing8};

  @media ${device.desktopLG} {
    font-size: 32px;
    line-height: 40px;
  }

  @media ${device.desktopXL} {
    font-size: 40px;
    line-height: 50px;
  }
`

export const BodyText = styled.p`
  font-size: 14px;
  line-height: 17px;
  color: ${grayscale[500]};

  @media ${device.tablet} {
    font-size: 16px;
    line-height: 19px;
  }

  @media ${device.desktopLG} {
    font-size: 18px;
    line-height: 21px;
  }
`

export const ContactCardTitle = styled.h4`
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  color: ${grayscale[500]};
  font-family: 'Sora', sans-serif;

  @media ${device.desktopLG} {
    font-size: 24px;
    line-height: 30px;
  }
`

export const ContactCardBody = styled.p`
  font-size: 14px;
  line-height: 17px;
  color: ${grayscale[400]};

  @media ${device.desktopLG} {
    font-size: 18px;
    line-height: 22px;
  }
`

export const ContactCardLinkBtn = styled.button`
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;
  color: #ff7a00;
`

export const ContactCardLink = styled.a`
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  color: #ff7a00;

  @media ${device.desktopLG} {
    font-size: 18px;
    line-height: 21px;
  }
`

export const ContactCard = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #DEDFE4;
  box-sizing: border-box;
  border-radius: 8px;
  background: #FFFFFF;
  padding: 16px;
  margin-top: 10px;
  width: auto;
  height: auto;

  ${ContactCardTitle},
  ${ContactCardBody} {
    margin-bottom: ${spacing8};
  }

  @media ${device.tablet} {
    &:first-child {
      margin-top: 0;
    }
    margin-top: 20px;
  }

  @media ${device.desktopLG} {
    padding: 16px;
  }
`

export const DisclaimerText = styled.p`
  font-size: 12px;
  line-height: 15px;
  color: ${grayscale[400]};
`
