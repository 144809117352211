import React from 'react'

import canaisJSON from '../../assets/data/canais.json'

import ChatWindow from 'components/ChatWindow'
import { EventCaller } from 'inter-site-components'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { canaisJSONprops, ICanaisData } from './_types'
import * as S from './style'

const canaisData: ICanaisData = canaisJSON

const CentralDeAtendimento = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <S.Section id='canais-de-atendimento'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12'>
            <S.MainSectionTitle>
              Canais de atendimento
              <span> e telefones Inter</span>
            </S.MainSectionTitle>
          </div>

          <div className='row d-flex align-items-md-center justify-content-md-between mb-md-5 pb-md-4'>
            <div className='col-12 col-md-6 col-xl-5'>
              <S.SecondarySectionTitle>Central de Atendimento</S.SecondarySectionTitle>
              <S.BodyText>
                Para falar com a gente sobre consultas, informações, transações, cancelamentos, renegociação de
                dívida e orientações para uso dos canais virtuais.
              </S.BodyText>
            </div>

            <div className='col-12 col-md-6 offset-xl-1'>
              {
                canaisData.centralDeAtendimento.map((item: canaisJSONprops) => (
                  <S.ContactCard key={item.title}>
                    <div className='mr-4' aria-hidden='true'>
                      <OrangeIcon size='LG' icon={item.icon} color='#ff7a00' />
                    </div>
                    <div>
                      <S.ContactCardTitle>{item.title}</S.ContactCardTitle>
                      <S.ContactCardBody>{item.subtitle}</S.ContactCardBody>
                      {item.hasLink && (
                        <div
                          onClick={() => {
                            sendDatalayerEvent({
                              section: 'dobra_03',
                              element_action: 'click button',
                              element_name: item.linkText ? item.linkText : 'null',
                              section_name: 'Canais de atendimento e telefones Inter',
                            })
                          }}
                        >
                          <EventCaller
                            action='sendGAEvent'
                            parameters='Chat;Botao;send'
                          />
                          <S.ContactCardLinkBtn
                            title={item.subtitle}
                            aria-label='Acessar chat 24 horas'
                            onClick={ChatWindow()}
                          >
                            {item.linkText}
                          </S.ContactCardLinkBtn>
                        </div>
                      )}
                    </div>
                  </S.ContactCard>
                ))
              }
            </div>
          </div>

          <div className='row d-flex align-items-md-center justify-content-md-between mb-md-5 pb-md-4'>
            <div className='col-12 col-md-6 pt-5 pt-md-0 col-xl-5'>
              <S.SecondarySectionTitle>SAC</S.SecondarySectionTitle>
              <S.BodyText>
                Para registrar reclamações, cancelamentos, sugestões, elogios e informações, o SAC é o
                canal de atendimento ideal para você!
              </S.BodyText>
            </div>
            <div className='col-12 col-md-6 offset-xl-1'>
              {
                canaisData.sac.map((item: canaisJSONprops) => (
                  <S.ContactCard key={item.title}>
                    <div className='mr-4' aria-hidden='true'>
                      <OrangeIcon size='LG' icon={item.icon} color='#ff7a00' />
                    </div>
                    <div>
                      <S.ContactCardTitle>{item.title}</S.ContactCardTitle>
                      <S.ContactCardBody>{item.subtitle}</S.ContactCardBody>
                      <a
                        href={item.link}
                        title={item.subtitle}
                        className='fs-14 lh-18 fw-700 text-orange--extra'
                        onClick={() => {
                          sendDatalayerEvent({
                            section: 'dobra_03',
                            element_action: 'click button',
                            element_name: item.linkText ? item.linkText : 'null',
                            section_name: 'Canais de atendimento e telefones Inter',
                            redirect_url: item.link,
                          })
                        }}
                      >
                        {item.linkText}
                      </a>
                    </div>
                  </S.ContactCard>
                ))
              }
            </div>
          </div>

          <div className='row d-flex align-items-md-center justify-content-md-between mb-md-5 pb-md-4'>
            <div className='col-12 col-md-6 col-xl-5 pt-5 pt-md-0'>
              <S.SecondarySectionTitle>Ouvidoria</S.SecondarySectionTitle>
              <S.BodyText>
                Para você que já recebeu nosso atendimento em outros canais, mas ainda não teve a solução que precisava.
              </S.BodyText>
            </div>

            <div className='col-12 col-md-6 offset-xl-1'>
              {
                canaisData.ouvidoria.map((item: canaisJSONprops) => (
                  <S.ContactCard key={item.title}>
                    <div className='mr-4' aria-hidden='true'>
                      <OrangeIcon size='LG' icon={item.icon} color='#ff7a00' />
                    </div>
                    <div>
                      <S.ContactCardTitle>{item.title}</S.ContactCardTitle>
                      <S.ContactCardBody>{item.subtitle}</S.ContactCardBody>
                      <S.ContactCardLink
                        href={item.link}
                        title={item.subtitle}
                        aria-label='Acessar o canal de atendimento online'
                        onClick={() => {
                          sendDatalayerEvent({
                            section: 'dobra_03',
                            element_action: 'click button',
                            element_name: item.linkText ? item.linkText : 'null',
                            section_name: 'Canais de atendimento e telefones Inter',
                            redirect_url: item.link,
                          })
                        }}
                      >
                        {item.linkText}
                      </S.ContactCardLink>
                    </div>
                  </S.ContactCard>
                ))
              }
            </div>
          </div>
          <div className='row d-flex align-items-md-center justify-content-md-between mb-md-5 pb-md-4'>
            <div className='col-12 col-md-6 col-xl-5 pt-5 pt-md-0'>
              <S.SecondarySectionTitle>
                Atendimento às pessoas com deficiência auditiva ou de fala
              </S.SecondarySectionTitle>
              <S.BodyText>
                Atendimento em Libras com tradução simultânea de intérprete para informações, reclamações,
                cancelamento, outros produtos e serviços.
              </S.BodyText>
            </div>
            <div className='col-12 col-md-6 offset-xl-1'>
              {
                canaisData.deficienciaAuditiva.map((item: canaisJSONprops) => (
                  <S.ContactCard key={item.title}>
                    <div className='mr-4' aria-hidden='true'>
                      <OrangeIcon size='LG' icon={item.icon} color='#ff7a00' />
                    </div>
                    <div>
                      <S.ContactCardTitle>{item.title}</S.ContactCardTitle>
                      <S.ContactCardLink
                        href={item.link}
                        title={item.subtitle}
                        aria-label='Iniciar atendimento em libras'
                        onClick={() => {
                          sendDatalayerEvent({
                            section: 'dobra_03',
                            element_action: 'click button',
                            element_name: item.linkText ? item.linkText : 'null',
                            section_name: 'Canais de atendimento e telefones Inter',
                            redirect_url: item.link,
                          })
                        }}
                      >
                        {item.linkText}
                      </S.ContactCardLink>
                    </div>
                  </S.ContactCard>
                ))
              }
            </div>
          </div>
          <S.DisclaimerText><strong>Não ligamos pra você por esses telefones.</strong> Também não solicitamos dados pessoais, senha da conta, código de transação por telefone. Estes números são apenas para você fazer ligações para o Inter.</S.DisclaimerText>
        </div>
      </div>
    </S.Section>
  )
}

export default CentralDeAtendimento
